<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton
                :showBtn="false"
                :showAddNew="true"
                btnTitle="Add New"
                title="Payment Voucher List"
                @onClickAddNewButton="onClickAddNewButton"
            />

            <div class="row match-height mt-1">
              <DateQuerySetter
                  @onClickGo="getPayments"
              >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">

                  <div class="d-flex align-items-center justify-content-between filter-label">
                    <label for="colFormLabel" class="col-form-label">Party: </label>
                    <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                    <i class="fas fa-search"></i>
                </span>
                  </div>

                  <AsyncSelect
                      placeholder="Select Party"
                      v-model="contactProfile"
                      :api-service="fetchContactProfiles"
                      :format-label="formatPatientLabel"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                  <label for="colFormLabel" class="col-form-label">Select Account Head:</label>
                  <v-select
                      v-model="account_head_id"
                      :options="accountHeads"
                      label="name"
                      :reduce="(name) => name.id"
                      placeholder="Select head"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                  <label
                      for="colFormLabel"
                      class="col-form-label"
                  >Business</label
                  >
                  <v-select
                      placeholder="Select Business"
                      class="w-100"
                      :options="businesses"
                      label="name"
                      :reduce="(name) => name.id"
                      v-model="business_id"
                  />
                </div>
                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                  <label for="colFormLabel" class="col-form-label">Project</label>
                  <v-select
                      placeholder="Select Project"
                      v-model="project_id"
                      :options="projects"
                      label="name"
                      :reduce="name => name.id"
                  />
                </div>
              </DateQuerySetter>
            </div>
        </div>

        <ListTable />
        <div class="mb-2"></div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination
                :offset="offset"
                @onPageChange="onPageChange"
                ref="pagination"
            />
        </div>
        <Loader v-if="loading"/>
        <ContactDropdownFilter
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :isFilterable="true"
            :companyRoles="companyRoles"
        />
    </div>
</template>

<script>
import handleJournal from '@/services/modules/journal'
import {inject, ref} from 'vue'
import { computed } from 'vue'
import {mapMutations, useStore} from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/ngo/payment/ListTable'
import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import handleProjects from "@/services/modules/procurement/project";
import handleContact from "@/services/modules/contact";
import handleCBusinesses from "@/services/modules/businesses";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import handleRole from "@/services/modules/role";

export default {
    name: 'JournalList',

    data: () => ({
        offset: 20,
        accountHeads: [],
        businesses : [],
        projects : [],

        account_head_id: null,
        business_id: null,
        project_id: null,
        contactProfile: null,
        companyRoles: []
    }),

    components: {
        ContactDropdownFilter,
        AsyncSelect,
        TitleButton,
        DateQuerySetter,
        ListTable,
        Pagination,
        Loader
    },
    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        start() {
            return this.$route.query.start
        },
        end() {
            return this.$route.query.end
        },
        page() {
            return this.$route.query.page
        },
    },
    methods: {
        ...mapMutations({
            setJournals: 'journal/setJournals',
            setPaginationData: 'setPaginationData',
            resetPagination: 'resetPagination'
        }),
        onClickAddNewButton() {
            this.$router.push({name: 'ngo-payment-voucher-create',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            })
        },

        async getPayments() {
            const query = this.getQuery()
           // return;
            try {
                this.loading = true
                let res = await this.fetchJournals(query)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.setJournals(res.data.data)
                    if(res.data.data.length > 0) {
                        this.setPagination(res.data)
                    }
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        setPagination(data) {
            this.setPaginationData({
                records: data.total,
                perPage: data.per_page,
                currentPage: data.current_page,
            })
        },

        onPageChange(page) {
            let routeQuery = Object.assign({}, this.$route.query)
            routeQuery.page = page
            this.$router.push({path: this.$route.path, query: routeQuery})
            setTimeout(() => {
                this.getPayments()
            },100)
        },

        getQuery () {
            let query = '?company_id=' + this.companyId
            if(!this.start && !this.end) return query
            if(this.start) query += '&start_date='+this.start
            if(this.end) query += '&end_date='+this.end
            query += '&offset='+this.offset
            if(this.page) query += '&page='+this.page
            if (this.contactProfile) query += "&contact_profile_id=" + this.contactProfile.id;
            if (this.account_head_id) query += "&account_head_id=" + this.account_head_id;
            if (this.business_id) query += "&business_id=" + this.business_id;
            if (this.project_id) query += "&project_id=" + this.project_id;
            query += '&voucher_type=payment_voucher'
            return query
        },

        onOpenContactSearchModal () {
          this.$store.state.isModalOpenThree = true;
        },

        onSearchContact (contact) {
          this.contactProfile = contact
        }
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const store = useStore()

        const { fetchProjects } = handleProjects()

        const { fetchContactProfiles} = handleContact()
        const { formatPatientLabel } = useAsyncDropdownHelper();
        const { fetchCompanyDefaultRoles } = handleRole();
        const { fetchBusinessList } = handleCBusinesses();

        const {
            fetchJournals,
            loading,
            fetchAccountHeads
        } = handleJournal()

        return {
            journals: computed(() => store.state.journal.journals),
            fetchJournals,
            loading,
            showError,
            showSuccess,
            fetchAccountHeads,
            fetchProjects,
            fetchContactProfiles,
            fetchBusinessList,
            formatPatientLabel,
            fetchCompanyDefaultRoles,
            store
        }
    },

  async mounted() {
        this.loading = true;
        let companyId = this.$route.params.companyId
        let contactId = '';
        let q = '';
        let companyQuery = '?company_id=' + companyId;
        let query = companyQuery + '&q=' + q + '&contact_id' + contactId;
        let projectQuery = companyQuery + '&with_donors=1';

        await this.getPayments()
        Promise.all([
          this.fetchAccountHeads(companyId).then(res => {
            if(res.data){
              this.accountHeads = res.data
            }
          }),
          this.fetchProjects(projectQuery).then(res => {
            if(res.data) {
              this.projects = res.data
            }
          }),
          this.fetchBusinessList(companyQuery).then(res => {
            if(res.data) {
              this.businesses = res.data;
            }
          }),
          this.fetchCompanyDefaultRoles(companyQuery).then(res => {
            if(res.data) {
              this.companyRoles = res.data;
            }
          })
        ]).then(() => {
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          this.showError(err.message)
        }).finally(() => {
          this.loading = false;
        })
      }
}
</script>
